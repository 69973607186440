.favourite {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    transition: fill 0.2s ease;

    &.active {
      fill: var(--red-danger-color) !important;

      & > path {
        transition: all 0.2s ease;
        stroke: var(--red-danger-color) !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &:hover {
      .icon {
        fill: var(--red-danger-color);

        & > path {
          stroke: var(--red-danger-color);
        }
      }
    }
  }
}
