.actions {
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  z-index: 2;

  @media screen and (max-width: 1023px) {
    top: -8px;
    right: -8px;
  }

  @media screen and (max-width: 767px) {
    top: -14px;
    right: -14px;
  }
}
